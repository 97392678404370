import axios from 'axios';

export default {
  async get() {
    const resp = await axios.get('/api/costumes');
    return resp.data;
  },
  async getOne(id) {
    const resp = await axios.get(`/api/costumes/${id}`);
    return resp.data;
  },
  async getSizes() {
    const resp = await axios.get('/api/costumes/sizes');
    return resp.data.reduce((p, c) => {
      p[c.type].push({ value: c.id, text: c.name });
      return p;
    }, { clothing: [], bra: [], shoes: [] });
  },
  async create(data) {
    const resp = await axios.post('/api/costumes', data);
    return resp.data;
  },
  async update(id, data) {
    const resp = await axios.put(`/api/costumes/${id}`, data);
    return resp.data;
  },
  async remove(id) {
    const resp = await axios.delete(`/api/costumes/${id}`);
    return resp.data;
  },
};

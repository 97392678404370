<template>
  <ui-transition>
    <div v-if="!isLoading">
      <div class="mb-6">
        <div
          v-if="uploading"
          class="rounded-xl w-full h-48 text-center flex justify-center items-center bg-level-3 bg-opacity-50 mb-2"
        >
          <font-awesome-icon
            :icon="['fas', 'circle-notch']"
            class="text-sub text-3xl"
            spin
          />
        </div>
        <img
          v-else-if="editedItem.thumbnail"
          :src="`/uploads/costumes/${editedItem.thumbnail}`"
          class="rounded-xl w-full h-48 object-cover shadow-xl mb-2"
        >
        <div
          v-else
          class="rounded-xl w-full h-48 text-center flex justify-center items-center bg-level-3 bg-opacity-50 mb-2"
        >
          <div class="text-sub text-opacity-50">
            画像未登録
          </div>
        </div>
        <div
          v-if="editedItem.thumbnail"
          class="grid grid-cols-2 gap-2"
        >
          <ui-file-upload
            target="costumes"
            button-class="w-full text-sm"
            @upload-begin="uploading = true"
            @upload-ok="editedItem.thumbnail = $event"
            @upload-end="uploading = false"
          >
            画像を変更
          </ui-file-upload>
          <ui-button
            color="error"
            class="w-full text-sm"
            @click="editedItem.thumbnail = ''"
          >
            画像を削除
          </ui-button>
        </div>
        <div v-else>
          <ui-file-upload
            target="costumes"
            class="w-full text-sm"
            @upload-begin="uploading = true"
            @upload-ok="editedItem.thumbnail = $event"
            @upload-end="uploading = false"
          >
            画像をアップロード
          </ui-file-upload>
        </div>
      </div>
      <ui-input
        v-model="editedItem.name"
        label="名称"
        class="mb-6"
        :required="true"
      />
      <ui-select
        v-model="editedItem.costume_size_id"
        label="サイズ"
        class="mb-6"
      >
        <option :value="0">
          不明
        </option>
        <optgroup label="洋服">
          <option
            v-for="option in sizes.clothing"
            :key="option.value"
            :value="option.value"
            v-text="option.text"
          />
        </optgroup>
        <optgroup label="ブラ">
          <option
            v-for="option in sizes.bra"
            :key="option.value"
            :value="option.value"
            v-text="option.text"
          />
        </optgroup>
        <optgroup label="靴下">
          <option
            v-for="option in sizes.shoes"
            :key="option.value"
            :value="option.value"
            v-text="option.text"
          />
        </optgroup>
      </ui-select>
      <ui-textarea
        v-model="editedItem.comment"
        label="コメント"
        class="mb-6"
      />
      <ui-button
        color="primary"
        class="w-full my-2"
        :disabled="disabled"
        @click="save"
      >
        保存
      </ui-button>
    </div>
  </ui-transition>
</template>

<script>
import CostumeService from '../services/CostumeService';

export default {
  name: 'EquipmentEdit',
  props: {
    id: {
      type: [String, Number],
      default: 'new',
    },
  },
  data: () => ({
    isLoading: true,
    sizes: {
      clothing: [],
      bra: [],
      shoes: [],
    },
    uploading: false,
    updating: false,
    editedItem: {
      name: '',
      stock: 1,
      thumbnail: '',
      costume_size_id: 0,
      comment: '',
    },
  }),
  computed: {
    disabled() {
      return !this.editedItem.name;
    },
  },
  async created() {
    this.fetch();
    this.sizes = await CostumeService.getSizes();
  },
  methods: {
    async fetch() {
      this.isLoading = true;
      if (this.id === 'new') {
        this.$nextTick(() => {
          this.isLoading = false;
        });
        return;
      }
      const costume = await CostumeService.getOne(this.id);
      this.editedItem.name = costume.name;
      this.editedItem.thumbnail = costume.thumbnail;
      if (costume.costume_size) {
        this.editedItem.costume_size_id = costume.costume_size.id;
      } else {
        this.editedItem.costume_size_id = 0;
      }
      this.editedItem.comment = costume.comment;
      this.isLoading = false;
    },
    async save() {
      this.updating = true;
      const costumeSizeId = this.editedItem.costume_size_id ? this.editedItem.costume_size_id : null;
      if (this.id === 'new') {
        await CostumeService.create({ ...this.editedItem, costume_size_id: costumeSizeId });
      } else {
        await CostumeService.update(this.id, { ...this.editedItem, costume_size_id: costumeSizeId });
      }
      this.updating = false;
      this.$store.dispatch('notification/set', {
        message: '衣装情報を保存しました！',
        type: 'success',
      });
      this.$router.back();
    },
  },
};
</script>
